@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');html, body {
  height: 100%;
  margin: 0;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.missionDark {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: white;
}

.light {
  font-family: 'Roboto', sans-serif;
  color: black;
}

.dark {
  font-family: 'Roboto', sans-serif;
  color: white;
}

.iconPad {
  padding-left: 2px;
}