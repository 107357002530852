@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

html, body {
  height: 100%;
  margin: 0;
}

.light {
  background-color: white;
}

.dark {
  background-color: #252528;
}

.content {
  min-height: 100%;
}

.content-inside {
  padding: 20px;
  padding-bottom: 50px;
}

.footer {
  height: 50px;
  margin-top: -50px;
  text-align: center;
}

h1 {
  text-align: center;
}

h3 {
  text-align: center;
}

.copyright {
  text-align: center;
}

.card {
  text-align: center;
}

/* Name Card */

.photo {
  background-color: black;
  border-color: black;
  border-width: 10px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
}

.photoDark {
  background-color: white;
  border-color: white;
  border-width: 10px;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
}

.text {
  font-family: 'Roboto', sans-serif;
}

/* Skills */

.left {
  text-align: left;
}

.meta {
  text-align: left;
  color: white;
}

.sun {
  margin-top: 10px;
  width: 100px;
  height: 100px;
}

.iconPad {
  padding-left: 2px;
}

/* Coins */

.coin {
  height: 200px;
  padding: 20px;
}

.coinTitle {
    font-size: 20px;
    /* color: yellow; */
    font-family: 'Press Start 2P', cursive;
    animation: blink 1s steps(1, end) infinite;
    padding: 5px;
}