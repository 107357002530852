@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');html, body {
    height: 100%;
    margin: 0;
}
  
.card {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 10px;
}

.photo {
    background-color: black;
    color: white;
    border-radius: 10px;
    border-width: 10px;
    border-color: black;
    border-style: solid;
}

.light {
    font-family: 'Roboto', sans-serif;
    color: black;
}
  
.dark {
    font-family: 'Roboto', sans-serif;
    color: white;
}

.bar {
    background-color: yellow;
    padding: 10px;
}

.break {
    visibility: hidden;
}

.projectType {
    padding: 40px;
}

#goku {
    padding: 10px;
}