@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Teko:wght@300;500&display=swap');

.blink {
    font-size: 30px;
    color: yellow;
    font-family: 'Press Start 2P', cursive;
    animation: blink 1s steps(1, end) infinite;
    padding: 5px;
}

.blink2 {
  font-size: 30px;
  color: red;
  font-family: 'Press Start 2P', cursive;
  animation: blink 1s steps(1, end) infinite;
  padding: 5px;
}
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

/* .card {
  height: 100px;
} */

/* Arcade: */

.background {
  background: linear-gradient(270deg, rgb(96, 181, 234), #1c58b8);
  -webkit-animation: waves 5s ease infinite;
  -moz-animation: waves 5s ease infinite;
  background-size: 200%;
  animation: waves 10s ease infinite;
  border-style: solid;
  border-color: black;
  border-width: 20px; border-style: solid;
  border-color: black;
  border-width: 20px;
}

@-webkit-keyframes waves {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes waves {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes waves { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.code {
  height: 200px;
  background: linear-gradient(270deg, #5fed87, #f0b607);
  -webkit-animation: waves 5s ease infinite;
  -moz-animation: waves 5s ease infinite;
  background-size: 200%;
  animation: waves 5s ease infinite;
  border-style: solid;
  border-color: black;
  border-width: 10px; 
  border-style: solid;
  border-color: black;
  border-width: 20px;
  border-radius: 10px;
}

@media (max-width: 800px) {
  .test {
    font-size: 6px;
  }
}